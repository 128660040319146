<template>
  <div>
    <h3 class="page-title">باشقۇرغۇچى باشقۇرۇش </h3>
    <el-divider></el-divider>
    <el-row class="rtl-right">
      <el-button type="primary" plain icon="el-icon-circle-plus-outline" @click="add" v-if="pageTags.create">قوشۇش </el-button>
    </el-row>
    <!-- table-box -->
    <div class="table-box" v-if="pageTags.list">
      <el-table
        :data="tableData" 
        border 
        style="width: 100%" 
        v-loading="loading">
        <el-table-column prop="username" label="باشقۇرغۇچى"></el-table-column>
        <el-table-column prop="name" label="ئىسىم"></el-table-column>
        <el-table-column prop="roles" label="رول">
          <template slot-scope="scope">
            <p v-for="(role,index) in scope.row.roles" :key="index">{{role.description}}<p/>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="تېلېفون"></el-table-column>
        <el-table-column prop="status" width="120" label="ھالىتى">
          <template slot-scope="scope">
            <label v-show="scope.row.status=='1'">ئوچۇق</label>
            <label v-show="scope.row.status=='0'">ئېتىك</label>
          </template>
        </el-table-column>
        <el-table-column label="مەشغۇلات" prop="dosome" width="300">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleEdit(scope.$index, scope.row)" v-if="pageTags.update">تەھرىرلەش</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)" v-if="pageTags.delete">ئۆچۈرۈش</el-button>
            <el-button
              size="mini"
              type="success"
              @click="handleRoles(scope.$index, scope.row)" v-if="pageTags.rolesStore">رول تەخسىملەش</el-button>
          </template>
        </el-table-column>
      </el-table> 
    </div>

    <!-- el-dialog manage-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div class="form-content">
        <el-form label-width="120px" :model="ruleForm" ref="ruleForm">
          <el-form-item label="باشقۇرغۇچى" prop="username">
            <el-input v-model="ruleForm.username" placeholder="باشقۇرغۇچى"></el-input>
          </el-form-item>
          <el-form-item label="پارول" prop="password">
            <el-input v-model="ruleForm.password" :type="pwdType" placeholder="پارول">
              <i slot="suffix" class="el-input__icon iconfont" :class="{'icon-close-eye':pwdType=='password','icon-eye':pwdType=='text'}" @click="viewPwd">
              </i>
            </el-input>
          </el-form-item>
          <el-form-item label="پارول مۇقىملاش" prop="password_confirmation">
            <el-input v-model="ruleForm.password_confirmation" :type="pwdType" placeholder="پارول مۇقىملاش">
              <i slot="suffix" class="el-input__icon iconfont" :class="{'icon-close-eye':pwdType=='password','icon-eye':pwdType=='text'}" @click="viewPwd">
              </i>
            </el-input>
          </el-form-item>
          <el-form-item label="ئىسمى" prop="name">
            <el-input v-model="ruleForm.name" placeholder="ئىسمى"></el-input>
          </el-form-item>
          <el-form-item label="تېلېفون" prop="phone">
            <el-input v-model="ruleForm.phone" placeholder="تېلېفون"></el-input>
          </el-form-item>
          <el-form-item label="ھالەت" prop="status" class="radio-form rtl-right">
            <el-radio v-model="ruleForm.status" label="1">ئوچۇق</el-radio>
            <el-radio v-model="ruleForm.status" label="0">ئېتىك</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitForm('ruleForm')">مۇقىملاش</el-button>
        <el-button type="warning" plain @click="resetForm('ruleForm')">ئەسلىگە قايتۇرۇش</el-button>
        <el-button type="danger" plain @click="closeDialog('ruleForm')">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

    <!-- el-dialog roles-->
    <el-dialog
      title="رول تەخسىملەش"
      :visible.sync="roleDialogVisible"
      width="30%"
      :before-close="roleHandleClose">
      <div class="form-content">
        <el-form label-width="120px" ref="roleForm">
          <el-form-item label="رول">
            <el-select v-model="roleIds" class="rtl-right" filterable multiple placeholder="رول">
              <el-option
                v-for="item in rolesList"
                :key="item.id"
                :label="item.description"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="submitRoleForm('roleForm')">مۇقىملاش</el-button>
        <el-button type="danger" @click="closeRoleDialog('roleForm')">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  var self;
  export default {
    activated: function() {
      self = this;
      self.getList();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        dialogVisible: false,
        pwdType:"password",
        ruleForm: {
          username:"",
          password:"",
          password_confirmation:"",
          name:"",
          phone:"",
          status:"1"
        },
        editIndex: -1,
        dialogTitle:"",
        addDialog:true,
        editId:"",
        roleDialogVisible:false,
        rolesList:[],
        roleIds:[],    
        adminId:[],
        pageTags:{}   
      };
    },
    methods: {
      getList() {
        self.loading = true;

        self.$fetch("admin/admins").then(response => {
          if (response.status == 200) {
           self.tableData = response.data.data;
           if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags")); 
           }
          } else{
            console.log(response.message);
          }

          self.loading = false;
        }).catch(err => {
          console.log('××××××××××××××');
          self.loading = false;
        });
      },
      add() {
        self.addDialog = true;
        self.dialogTitle = "قوشۇش ";
        self.dialogVisible = true;
      },
      handleEdit(index, row) {
        console.log(row);
        self.addDialog = false;
        self.dialogTitle = "تەھرىرلەش ";
        self.editId = row.id;
        self.ruleForm.username = row.username;
        self.ruleForm.name = row.name;
        self.ruleForm.phone = row.phone;
        self.ruleForm.status = row.status + '';
        self.dialogVisible = true;
      },
      handleDelete(index, row) {
        self.$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
          confirmButtonText: "مۇقىملاش",
          cancelButtonText: "بىكار قىلىش",
          type: "warning"
        }).then(() => {
          self.$remove('admin/admins/'+row.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              self.$message({
                message: "ئۆچۈرۈش تاماملاندى",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
            console.log(err);
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "ئۆچۈرۈش بىكار قىلىندى"
          });
        });
      },
      getRolesList() {
        self.$fetch("admin/roles").then(response => {
          if (response.status == 200) {
           self.rolesList = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      viewPwd(){
        if(self.pwdType == "text"){
          self.pwdType = "password";
        }else{
          self.pwdType = "text";
        }
      },
      submitForm(formName) {
        var data = {
          username:self.ruleForm.username,
          password:self.ruleForm.password,
          password_confirmation:self.ruleForm.password_confirmation,
          name:self.ruleForm.name,
          phone:self.ruleForm.phone,
          status:self.ruleForm.status
        }
        if(self.addDialog){
          self.$post('admin/admins',data).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response.data.message);
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }else{
          self.$put('admin/admins/'+self.editId,data).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              self.$message({
                message: response.data.message,
                type: "warning",
                duration: 1500
              });
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }
      },
      resetForm(formName) {
        self.$refs[formName].resetFields();
        self.ruleForm = {
          username:"",
          password:"",
          password_confirmation:"",
          name:"",
          phone:"",
          status:"1"
        }
      },
      closeDialog(formName) {
        self.resetForm(formName);
        self.dialogVisible = false;
      },
      handleClose(done) {
        done();
        self.resetForm("ruleForm");
      },
      roleHandleClose(done){
        done();
        self.$refs.roleForm.resetFields();
        self.roleIds = [];
      },
      closeRoleDialog(){
        self.roleDialogVisible = false;
        self.roleIds = [];
      },
      handleRoles(index,row){
        self.getRolesList();
        self.adminId = row.id;

        console.log(row);

        self.roleDialogVisible = true;
      },
      submitRoleForm(formName){
        console.log(self.roleIds);
        self.$post('admin/admins/'+self.adminId+'/roles',{
          roles_id:self.roleIds
        }).then((response) => {
          if(response.status == 201){
            self.closeRoleDialog();
            self.getList();
          }else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      }
    }
  };
</script>

